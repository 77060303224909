import React from 'react';
import PropTypes from 'prop-types';
import {catalogItemType} from '../../types';
import {GuitarTypeParamsObject} from '../../constants';
import './guitar-info-block.scss';

const GuitarInfoBlock = (props) => {
  const {className, catalogItem, isPriceHidden} = props;
  const {
    article,
    name,
    type,
    imgSrc,
    imgSrcSet,
    stringsAmount,
    priceTitle,
  } = catalogItem;

  return (
    <article
      className={[className, 'guitar-info-block'].join(' ').trim()}
      data-testid='guitar-info-block'
    >
      <img className='guitar-info-block__img' src={imgSrc} srcSet={`${imgSrcSet} 2x`} width='48' height='124' alt={name} />
      <h3 className='guitar-info-block__title'>{`${GuitarTypeParamsObject[type].title} ${name}`}</h3>
      <p className='guitar-info-block__article'>{`Артикул: ${article}`}</p>
      <p className='guitar-info-block__info'>{`${GuitarTypeParamsObject[type].title}, ${stringsAmount} струнная`}</p>
      {
        isPriceHidden
          ? null
          : (
            <p className='guitar-info-block__price' data-testid='guitar-info-block__price'>
              {`Цена: ${priceTitle}`}
            </p>
          )
      }
    </article>
  );
};

GuitarInfoBlock.propTypes = {
  className: PropTypes.string,
  catalogItem: catalogItemType,
  isPriceHidden: PropTypes.bool,
};

GuitarInfoBlock.defaultProps = {
  className: '',
  isPriceHidden: false,
};

export {GuitarInfoBlock};
export default GuitarInfoBlock;
