import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Page404 from '../page-404/page-404';
import PageCart from '../page-cart/page-cart';
import PageCatalog from '../page-catalog/page-catalog';
import './app.scss';

const App = () => (
  <Routes>
    <Route path='/' element={<PageCatalog />} />
    <Route path='/cart' element={<PageCart />} />
    <Route path='*' element={<Page404 />} />
  </Routes>
);

export default App;
