import {ActionType} from '../actions/cart';
import {CART_ITEM_IDS_OBJECT_KEY} from '../../constants';

const initialState = {
  cartItemIdsObject: new Map(),
  promocodeValue: '',
  isLoadPromocode: false,
};

const saveCartItemIdsObject = (cartItemIdsObject) => {
  localStorage.setItem(
    CART_ITEM_IDS_OBJECT_KEY,
    JSON.stringify(Object.fromEntries(cartItemIdsObject))
  );
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.ADD_ITEM_ID_AT_CART_LIST: {
      const newCartItemIdsObject = new Map(state.cartItemIdsObject);
      const catalogItemId = action.payload;

      if (newCartItemIdsObject.has(catalogItemId)) {
        newCartItemIdsObject.set(catalogItemId, newCartItemIdsObject.get(catalogItemId) + 1);
      } else {
        newCartItemIdsObject.set(catalogItemId, 1);
      }

      saveCartItemIdsObject(newCartItemIdsObject);

      return {
        ...state,
        ...{cartItemIdsObject: newCartItemIdsObject},
      };
    }
    case ActionType.INCREASE_ITEM_NUMBER: {
      const newCartItemIdsObject = new Map(state.cartItemIdsObject);
      const catalogItemId = action.payload;

      if (newCartItemIdsObject.has(catalogItemId)) {
        newCartItemIdsObject.set(catalogItemId, newCartItemIdsObject.get(catalogItemId) + 1);
      }

      saveCartItemIdsObject(newCartItemIdsObject);

      return {
        ...state,
        ...{cartItemIdsObject: newCartItemIdsObject},
      };
    }
    case ActionType.REDUCE_ITEM_NUMBER: {
      const newCartItemIdsObject = new Map(state.cartItemIdsObject);
      const catalogItemId = action.payload;

      if (newCartItemIdsObject.has(catalogItemId)) {
        newCartItemIdsObject.set(catalogItemId, newCartItemIdsObject.get(catalogItemId) - 1);
      }

      saveCartItemIdsObject(newCartItemIdsObject);

      return {
        ...state,
        ...{cartItemIdsObject: newCartItemIdsObject},
      };
    }
    case ActionType.DELETE_ITEM_ID_FROM_CART_LIST: {
      const newCartItemIdsObject = new Map(state.cartItemIdsObject);
      const catalogItemId = action.payload;

      newCartItemIdsObject.delete(catalogItemId);

      saveCartItemIdsObject(newCartItemIdsObject);

      return {
        ...state,
        ...{cartItemIdsObject: newCartItemIdsObject},
      };
    }
    case ActionType.LOAD_CART_ITEM_IDS_OBJECT:
      return {
        ...state,
        ...{cartItemIdsObject: action.payload},
      };
    case ActionType.CHANGE_PROMOCODE_VALUE:
      return {
        ...state,
        ...{promocodeValue: action.payload},
      };
    case ActionType.CHANGE_LOAD_PROMOCODE_STATUS:
      return {
        ...state,
        ...{isLoadPromocode: action.payload},
      };
    default:
      return state;
  }
};

export default reducer;
