import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ButtonActionSmall from '../button-action-small/button-action-small';
import PopupGuitarInfo from '../popup-guitar-info/popup-guitar-info';
import {ReactComponent as RateSvg} from './img/rate.svg';
import Action from '../../store/root-action';
import {catalogItemType} from '../../types';
import {StoreNameSpace} from '../../constants';
import './catalog-item.scss';

class CatalogItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleGuitarBuyButtonClick = this.handleGuitarBuyButtonClick.bind(this);
  }

  handleGuitarBuyButtonClick() {
    const {
      changePopupVisibility,
      changePopupRenderFunc,
      catalogItem,
    } = this.props;

    changePopupVisibility(true);
    changePopupRenderFunc(() => (<PopupGuitarInfo catalogItemId={catalogItem.id} />));
  }

  render() {
    const {catalogItem, className} = this.props;
    const {id, name, imgSrc, imgSrcSet, rate, priceTitle, reviewsNumber} = catalogItem;

    return (
      <li className={[className, 'catalog-item'].join(' ').trim()}>
        <img className='catalog-item__img' src={imgSrc} srcSet={`${imgSrcSet} 2x`} width='68' height='190' alt={name} />
        <div className={`catalog-item__rate catalog-item__rate--${rate}`}>
          <RateSvg />
          <span className='catalog-item__reviews-number'>{reviewsNumber}</span>
        </div>
        <h3 className='catalog-item__title'>{name}</h3>
        <p className='catalog-item__price'>{priceTitle}</p>
        <Link
          className='catalog-item__more-button button-action-small'
          to={`/catalog/${id}`}
        >
          Подробнее
        </Link>
        <ButtonActionSmall
          addClass='catalog-item__buy-button'
          title='Купить'
          onClick={this.handleGuitarBuyButtonClick}
        />
      </li>
    );
  }
}

CatalogItem.propTypes = {
  className: PropTypes.string,
  catalogItem: catalogItemType,
  changePopupVisibility: PropTypes.func,
  changePopupRenderFunc: PropTypes.func,
};

CatalogItem.defaultProps = {
  className: '',
};

const mapDispatchToProps = (dispatch) => ({
  changePopupVisibility(isPopupVisible) {
    dispatch(Action[StoreNameSpace.POPUP].changePopupVisibility(isPopupVisible));
  },
  changePopupRenderFunc(popupRenderFunc) {
    dispatch(Action[StoreNameSpace.POPUP].changePopupRenderFunc(popupRenderFunc));
  },
});

export {CatalogItem};
export default connect(null, mapDispatchToProps)(CatalogItem);
