const ActionType = {
  ADD_ITEM_ID_AT_CART_LIST: 'ADD_ITEM_ID_AT_CART_LIST',
  DELETE_ITEM_ID_FROM_CART_LIST: 'DELETE_ITEM_ID_FROM_CART_LIST',
  INCREASE_ITEM_NUMBER: 'INCREASE_ITEM_NUMBER',
  REDUCE_ITEM_NUMBER: 'REDUCE_ITEM_NUMBER',
  LOAD_CART_ITEM_IDS_OBJECT: 'LOAD_CART_ITEM_IDS_OBJECT',
  CHANGE_PROMOCODE_VALUE: 'CHANGE_PROMOCODE_VALUE',
  CHANGE_LOAD_PROMOCODE_STATUS: 'CHANGE_LOAD_PROMOCODE_STATUS',
};

const Action = {
  addItemIdAtCartList: (catalogItemId) => ({
    type: ActionType.ADD_ITEM_ID_AT_CART_LIST,
    payload: catalogItemId,
  }),
  deleteItemIdFromCartList: (catalogItemId) => ({
    type: ActionType.DELETE_ITEM_ID_FROM_CART_LIST,
    payload: catalogItemId,
  }),
  increaseCartItemNumber: (catalogItemId) => ({
    type: ActionType.INCREASE_ITEM_NUMBER,
    payload: catalogItemId,
  }),
  reduceCartItemNumber: (catalogItemId) => ({
    type: ActionType.REDUCE_ITEM_NUMBER,
    payload: catalogItemId,
  }),
  loadCartItemIdsObjects: (cartItemIdsObject) => ({
    type: ActionType.LOAD_CART_ITEM_IDS_OBJECT,
    payload: cartItemIdsObject,
  }),
  changePromocodeValue: (promocodeValue) => ({
    type: ActionType.CHANGE_PROMOCODE_VALUE,
    payload: promocodeValue,
  }),
  changeLoadPromocodeStatus: (loadPromocodeStatus) => ({
    type: ActionType.CHANGE_LOAD_PROMOCODE_STATUS,
    payload: loadPromocodeStatus,
  }),
};


export {
  ActionType,
  Action,
};
