import React from 'react';
import PropTypes from 'prop-types';
import './catalog-page-button.scss';

const WRAPPER_BUTTON_CLASS = 'catalog-page-button';
const WRAPPER_BUTTON_TEXT_MODE_CLASS = `${WRAPPER_BUTTON_CLASS}--text`;

const BUTTON_CLASS = 'catalog-page-button__button';
const BUTTON_ACTIVE_CLASS = `${BUTTON_CLASS}--active`;

const CatalogPageButton = (props) => {
  const {title, isTextButton, isActive, onClick, mode, testId} = props;

  const wrapperClassName = [
    WRAPPER_BUTTON_CLASS,
    isTextButton ? WRAPPER_BUTTON_TEXT_MODE_CLASS : '',
  ].join(' ').trim();

  const buttonClassName = [
    BUTTON_CLASS,
    isActive ? BUTTON_ACTIVE_CLASS : '',
  ].join(' ').trim();

  return (
    <li className={wrapperClassName}>
      <button
        className={buttonClassName}
        disabled={isActive}
        onClick={onClick}
        data-mode={mode}
        data-testid={testId}
      >
        {title}
      </button>
    </li>
  );
};

CatalogPageButton.propTypes = {
  title: PropTypes.string.isRequired,
  isTextButton: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  mode: PropTypes.string,
  testId: PropTypes.string,
};

CatalogPageButton.defaultProps = {
  isTextButton: false,
  isActive: false,
  mode: null,
  testId: 'catalog-page-button',
};

export {CatalogPageButton};
export default CatalogPageButton;
