import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import MainTitle from '../main-title/main-title';
import LogoLink from '../logo-link/logo-link';
import {ReactComponent as MapIcon} from './img/map.svg';
import {ReactComponent as SearchIcon} from './img/search.svg';
import {ReactComponent as CartIcon} from './img/cart.svg';
import {StoreNameSpace} from '../../constants';
import {breadcrumbsType, cartItemIdsObjectType} from '../../types';
import './main-header.scss';

const Links = [
  {
    title: 'Каталог',
    href: '/catalog',
  },
  {
    title: 'Где купить?',
    href: '/buy',
  },
  {
    title: 'О компании',
    href: '/company',
  },
  {
    title: 'Cервис-центры',
    href: '/service',
  },
];

const CART_TOOL_TITLE = 'Корзина';

const ToolsLinks = [
  {
    title: 'Карта',
    href: '/map',
    icon: MapIcon,
  },
  {
    title: 'Поиск',
    href: '/search',
    icon: SearchIcon,
  },
  {
    title: CART_TOOL_TITLE,
    href: '/cart',
    icon: CartIcon,
  },
];

const HEADER_LINK_CLASS = 'main-header__link';
const HEADER_LINK_ACTIVE_CLASSES_GROUP = `${HEADER_LINK_CLASS} ${HEADER_LINK_CLASS}--current`;

const HEADER_TOOL_CLASS = 'main-header__tool';
const HEADER_TOOL_ACTIVE_CLASSES_GROUP = `${HEADER_TOOL_CLASS} ${HEADER_TOOL_CLASS}--current`;

const HEADER_BREADCRUMB_CLASS = 'main-header__breadcrumb';
const HEADER_BREADCRUMB_ACTIVE_CLASSES_GROUP = `${HEADER_BREADCRUMB_CLASS} ${HEADER_BREADCRUMB_CLASS}--current`;

const MainHeader = (props) => (
  <header
    className={['main-header', props.addClass].join(' ').trim()}
    data-testid='main-header'
  >
    <nav className='main-header__nav'>
      <LogoLink addClass='main-header__logo' />
      <ul className='main-header__links'>
        {Links.map(({title, href}) => (
          <li className='main-header__link-wrapper' key={href}>
            <NavLink
              className={({isActive}) => isActive ? HEADER_LINK_ACTIVE_CLASSES_GROUP : HEADER_LINK_CLASS}
              to={href}
              tabIndex={window.location.pathname === href ? -1 : null}
            >
              {title}
            </NavLink>
          </li>
        ))}
      </ul>
      <ul className='main-header__tools'>
        {ToolsLinks.map(({title, href, icon: IconSvg}) => (
          <li className='main-header__tool-wrapper' key={href}>
            <NavLink
              className={({isActive}) => isActive ? HEADER_TOOL_ACTIVE_CLASSES_GROUP : HEADER_TOOL_CLASS}
              to={href}
              tabIndex={window.location.pathname === href ? -1 : null}
              aria-label={title}
            >
              <IconSvg />
              {
                title === CART_TOOL_TITLE && props.cartItemIdsObject.size > 0
                  ? (<span className='main-header__tool-cart-counter'>{props.cartItemIdsObject.size}</span>)
                  : null
              }
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
    <MainTitle title={props.title} />
    <ul className='main-header__breadcrumbs'>
      {props.breadcrumbs.map(({title, href}, index) => (
        <li className='main-header__breadcrumb-wrapper' key={href}>
          <NavLink
            className={({isActive}) => isActive || index === (props.breadcrumbs.length - 1) ? HEADER_BREADCRUMB_ACTIVE_CLASSES_GROUP : HEADER_BREADCRUMB_CLASS}
            to={href}
            tabIndex={
              window.location.pathname === href || index === (props.breadcrumbs.length - 1)
                ? -1
                : null
            }
          >
            {title}
          </NavLink>
        </li>
      ))}
    </ul>
  </header>
);

MainHeader.propTypes = {
  addClass: PropTypes.string,
  title: PropTypes.string.isRequired,
  breadcrumbs: breadcrumbsType,
  cartItemIdsObject: cartItemIdsObjectType,
};

MainHeader.defaultProps = {
  addClass: null,
};

const mapStateToProps = (state) => ({
  cartItemIdsObject: state[StoreNameSpace.CART].cartItemIdsObject,
});

export {MainHeader};
export default connect(mapStateToProps)(MainHeader);
