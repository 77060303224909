const ActionType = {
  CHANGE_MIN_PRICE: 'CHANGE_MIN_PRICE',
  CHANGE_MAX_PRICE: 'CHANGE_MAX_PRICE',
  CHANGE_CHECKED_GUITAR_TYPES: 'CHANGE_CHECKED_GUITAR_TYPES',
  CHANGE_STRINGS_AMOUNTS_STATE: 'CHANGE_STRINGS_AMOUNTS_STATE',
  SET_INIT_MIN_PRICE: 'SET_INIT_MIN_PRICE',
  SET_INIT_MAX_PRICE: 'SET_INIT_MAX_PRICE',
};

const Action = {
  changeMinPrice: (minPrice) => ({
    type: ActionType.CHANGE_MIN_PRICE,
    payload: minPrice,
  }),
  changeMaxPrice: (maxPrice) => ({
    type: ActionType.CHANGE_MAX_PRICE,
    payload: maxPrice,
  }),
  changeCheckedGuitarTypes: (checkedGuitarTypes) => ({
    type: ActionType.CHANGE_CHECKED_GUITAR_TYPES,
    payload: checkedGuitarTypes,
  }),
  changeStringsAmountsState: (StringsAmountsState) => ({
    type: ActionType.CHANGE_STRINGS_AMOUNTS_STATE,
    payload: StringsAmountsState,
  }),
  setInitMinPrice: (initMinPrice) => ({
    type: ActionType.SET_INIT_MIN_PRICE,
    payload: initMinPrice,
  }),
  setInitMaxPrice: (initMaxPrice) => ({
    type: ActionType.SET_INIT_MAX_PRICE,
    payload: initMaxPrice,
  }),
};


export {
  ActionType,
  Action,
};
