import React from 'react';
import Page from '../page/page';
import Filter from '../filter/filter';
import Sort from '../sort/sort';
import Catalog from '../catalog/catalog';
import './page-catalog.scss';

const PageCatalog = () => (
  <Page
    pageClassName='page-catalog'
    title='Каталог гитар'
    breadcrumbs={[
      {
        title: 'Главная',
        href: '/',
      },
      {
        title: 'Каталог',
        href: '/catalog',
      },
    ]}
  >
    <Filter />
    <Sort />
    <Catalog />
  </Page>
);

export {PageCatalog};
export default PageCatalog;
