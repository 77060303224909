const StoreNameSpace = {
  CATALOG: 'CATALOG',
  FILTER: 'FILTER',
  SORT: 'SORT',
  POPUP: 'POPUP',
  CART: 'CART',
};

const NUMBER_CATALOG_ITEMS_ON_PAGE = 9;

/*
  Ключи в GuitarType соотвествуют id и не написаны в upperCase,
  чтобы убрать лишние преобразования строк при обращении
*/
const GuitarTypeParamsObject = {
  acoustic: {
    id: 'acoustic',
    searchGuitarTypeId: 'ACOUSTIC',
    title: 'Акустическая гитара',
    filterTitle: 'Акустические гитары',
    stringsAmounts: new Set(),
  },
  electric: {
    id: 'electric',
    searchGuitarTypeId: 'ELECTRIC',
    title: 'Электрогитара',
    filterTitle: 'Электрогитары',
    stringsAmounts: new Set(),
  },
  ukulele: {
    id: 'ukulele',
    searchGuitarTypeId: 'UKULELE',
    title: 'Укулеле',
    filterTitle: 'Укулеле',
    stringsAmounts: new Set(),
  },
};

const SortType = {
  PRICE: 'price',
  POPYLARITY: 'popularity',
};

const SortDirection = {
  DESC: 'descending',
  ASC: 'ascending',
};

const FOCUS_ELEMENTS = [
  'a[href]:not([tabindex="-1"])',
  'input:not([disabled]):not([tabindex="-1"])',
  'button:not([disabled]):not([tabindex="-1"])',
  'select:not([tabindex="-1"])',
  'textarea:not([tabindex="-1"])',
  '[tabindex]:not([tabindex="-1"])',
];

const Key = {
  ESC: 'Escape',
  TAB: 'Tab',
  SHIFT: 'Shift',
};

const BODY_SCROLL_HIDDEN_ATTRIBUTE = 'data-scroll-hidden';

const CART_ITEM_IDS_OBJECT_KEY = 'CART_ITEM_IDS_OBJECT_KEY';
const PROMOCODE_VALUE_KEY = 'PROMOCODE_VALUE_KEY';

export {
  NUMBER_CATALOG_ITEMS_ON_PAGE,
  GuitarTypeParamsObject,
  SortType,
  SortDirection,
  FOCUS_ELEMENTS,
  Key,
  BODY_SCROLL_HIDDEN_ATTRIBUTE,
  CART_ITEM_IDS_OBJECT_KEY,
  PROMOCODE_VALUE_KEY,
  StoreNameSpace,
};
