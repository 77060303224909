import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Action from '../../store/root-action';
import ButtonClose from '../button-close/button-close';
import {
  isEscKeyDown,
  getFocusableElements,
  getNextArrayIndex,
  getPreviousArrayIndex,
} from '../../utils';
import {BODY_SCROLL_HIDDEN_ATTRIBUTE, Key, StoreNameSpace} from '../../constants';
import './popup.scss';

const RIGHT_OFFSET_PROPERTY = 'padding-right';

class Popup extends React.Component {
  constructor(props) {
    super(props);

    this.popupRef = React.createRef();
    this.previousFocusableElement = document.body;

    this.handleEscKeyDown = this.handleEscKeyDown.bind(this);
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
    this.handleContentClick = this.handleContentClick.bind(this);
    this.handleFocusElementChange = this.handleFocusElementChange.bind(this);
  }

  componentDidMount() {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    document.documentElement.style.setProperty(RIGHT_OFFSET_PROPERTY, `${scrollbarWidth}px`);
    document.documentElement.setAttribute(BODY_SCROLL_HIDDEN_ATTRIBUTE, true);

    this.previousFocusableElement = document.activeElement;

    document.addEventListener('keydown', this.handleEscKeyDown);
    document.addEventListener('keydown', this.handleFocusElementChange);
  }

  componentWillUnmount() {
    document.documentElement.style.removeProperty(RIGHT_OFFSET_PROPERTY);
    document.documentElement.removeAttribute(BODY_SCROLL_HIDDEN_ATTRIBUTE);

    this.previousFocusableElement.focus();

    document.removeEventListener('keydown', this.handleEscKeyDown);
    document.removeEventListener('keydown', this.handleFocusElementChange);
  }

  closePopup() {
    const {changePopupVisibility} = this.props;

    changePopupVisibility(false);
  }

  handleOverlayClick(evt) {
    this.closePopup(evt);
  }

  handleCloseButtonClick(evt) {
    this.closePopup(evt);
  }

  handleContentClick(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  handleEscKeyDown(evt) {
    if (isEscKeyDown(evt)) {
      this.closePopup(evt);
    }
  }

  handleFocusElementChange(evt) {
    if (evt.key === Key.TAB) {

      const focusableElements = getFocusableElements(this.popupRef.current);
      let indexElement = 0;

      evt.preventDefault();

      if (evt.shiftKey) {
        indexElement = getPreviousArrayIndex(
          focusableElements.indexOf(document.activeElement),
          focusableElements
        );
      } else {
        indexElement = getNextArrayIndex(
          focusableElements.indexOf(document.activeElement),
          focusableElements
        );
      }

      focusableElements[indexElement].focus();
    }
  }

  render() {
    const {children, className, popupContentClass, popupCloseButtonClass} = this.props;

    return (
      <div
        className={[className, 'popup'].join(' ').trim()}
        onClick={this.handleOverlayClick}
        ref={this.popupRef}
      >
        <div
          className={[popupContentClass, 'popup__content'].join(' ').trim()}
          onClick={this.handleContentClick}
        >
          {children}
          <ButtonClose
            className={[popupCloseButtonClass, 'popup__close-button'].join(' '.trim())}
            type='button'
            onClick={this.handleCloseButtonClick}
            ariaLabel='Закрыть окно'
          >
          </ButtonClose>
        </div>
      </div>
    );
  }
}

Popup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  popupContentClass: PropTypes.string,
  popupCloseButtonClass: PropTypes.string,
  changePopupVisibility: PropTypes.func,
};

Popup.defaultProps = {
  className: '',
  popupContentClass: '',
  popupCloseButtonClass: '',
};

const mapDispatchToProps = (dispatch) => ({
  changePopupVisibility(isPopupVisible) {
    dispatch(Action[StoreNameSpace.POPUP].changePopupVisibility(isPopupVisible));
  },
});

export {Popup};
export default connect(null, mapDispatchToProps)(Popup);
