import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import GuitarInfoBlock from '../guitar-info-block/guitar-info-block';
import ButtonClose from '../button-close/button-close';
import PopupDeleteCart from '../popup-delete-cart/popup-delete-cart';
import Action from '../../store/root-action';
import {StoreNameSpace} from '../../constants';
import {createRubString} from '../../utils';
import {catalogItemType, cartItemIdsObjectType} from '../../types';
import './cart-list-item.scss';

class CartListItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.handleAddCounterButtonClick = this.handleAddCounterButtonClick.bind(this);
    this.handleSubCounterButtonClick = this.handleSubCounterButtonClick.bind(this);
  }

  openPopupDeleteCart() {
    const {
      changePopupVisibility,
      changePopupRenderFunc,
      catalogItem,
    } = this.props;

    changePopupVisibility(true);
    changePopupRenderFunc(() => (<PopupDeleteCart catalogItemId={catalogItem.id} />));
  }

  handleDeleteButtonClick() {
    this.openPopupDeleteCart();
  }

  handleAddCounterButtonClick() {
    const {catalogItem, increaseCartItemNumber} = this.props;

    increaseCartItemNumber(catalogItem.id);
  }

  handleSubCounterButtonClick() {
    const {catalogItem, cartItemIdsObject, reduceCartItemNumber} = this.props;

    if (cartItemIdsObject.get(catalogItem.id) > 1) {
      reduceCartItemNumber(catalogItem.id);
    } else {
      this.openPopupDeleteCart();
    }
  }

  render() {
    const {catalogItem, className, cartItemIdsObject} = this.props;
    const catalogItemNumber = cartItemIdsObject.get(catalogItem.id);

    return (
      <li
        className={[className, 'cart-list-item'].join(' ').trim()}
        data-testid='cart-list-item'
      >
        <ButtonClose
          className='cart-list-item__delete-button'
          onClick={this.handleDeleteButtonClick}
          ariaLabel='Удалить товар из корзины'
        />
        <GuitarInfoBlock
          className='cart-list-item__info-block'
          catalogItem={catalogItem}
          isPriceHidden
        />
        <p className='cart-list-item__price'>{catalogItem.priceTitle}</p>
        <div className='cart-list-item__counter-wrapper'>
          <button
            className='cart-list__counter-button cart-list__counter-button--sub'
            type='button'
            aria-label='Уменьшить количество'
            onClick={this.handleSubCounterButtonClick}
            data-testid='cart-list__counter-button--sub'
          >
          </button>
          <span className='cart-list-item__counter'>{catalogItemNumber}</span>
          <button
            className='cart-list__counter-button cart-list__counter-button--add'
            type='button'
            aria-label='Увеличить количество'
            onClick={this.handleAddCounterButtonClick}
            data-testid='cart-list__counter-button--add'
          >
          </button>
        </div>
        <p className='cart-list-item__total-price'>{`${createRubString(catalogItemNumber * catalogItem.price)}`}</p>
      </li>
    );
  }
}

CartListItem.propTypes = {
  className: PropTypes.string,
  catalogItem: catalogItemType,
  changePopupVisibility: PropTypes.func,
  changePopupRenderFunc: PropTypes.func,
  cartItemIdsObject: cartItemIdsObjectType,
  increaseCartItemNumber: PropTypes.func,
  reduceCartItemNumber: PropTypes.func,
};

CartListItem.defaultProps = {
  className: '',
};

const mapStateToProps = (state) => ({
  cartItemIdsObject: state[StoreNameSpace.CART].cartItemIdsObject,
});

const mapDispatchToProps = (dispatch) => ({
  changePopupVisibility(isPopupVisible) {
    dispatch(Action[StoreNameSpace.POPUP].changePopupVisibility(isPopupVisible));
  },
  changePopupRenderFunc(popupRenderFunc) {
    dispatch(Action[StoreNameSpace.POPUP].changePopupRenderFunc(popupRenderFunc));
  },
  increaseCartItemNumber(catalogItemId) {
    dispatch(Action[StoreNameSpace.CART].increaseCartItemNumber(catalogItemId));
  },
  reduceCartItemNumber(catalogItemId) {
    dispatch(Action[StoreNameSpace.CART].reduceCartItemNumber(catalogItemId));
  },
});

export {CartListItem};
export default connect(mapStateToProps, mapDispatchToProps)(CartListItem);
