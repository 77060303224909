const ActionType = {
  CHANGE_POPUP_VISIBILITY: 'CHANGE_POPUP_VISIBILITY',
  CHANGE_POPUP_RENDER_FUNC: 'CHANGE_POPUP_RENDER_FUNC',
};

const Action = {
  changePopupVisibility: (isPopupVisible) => ({
    type: ActionType.CHANGE_POPUP_VISIBILITY,
    payload: isPopupVisible,
  }),
  changePopupRenderFunc: (popupRenderFunc) => ({
    type: ActionType.CHANGE_POPUP_RENDER_FUNC,
    payload: popupRenderFunc,
  }),
};


export {
  ActionType,
  Action,
};
