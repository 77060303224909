import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Popup from '../popup/popup';
import GuitarInfoBlock from '../guitar-info-block/guitar-info-block';
import ButtonAction from '../button-action/button-action';
import PopupSuccessCatalog from '../popup-success-catalog/popup-success-catalog';
import Action from '../../store/root-action';
import {getAllCatalogItems} from '../../store/selectors';
import {catalogItemsType} from '../../types';
import {StoreNameSpace} from '../../constants';
import './popup-guitar-info.scss';

class PopupGuitarInfo extends React.Component {
  constructor(props) {
    super(props);

    const {catalogItemId, allCatalogItems} = props;

    this.state = {
      catalogItem: allCatalogItems.find((item) => catalogItemId === item.id),
    };

    this.handleAddCartButtonClick = this.handleAddCartButtonClick.bind(this);
  }

  handleAddCartButtonClick() {
    const {changePopupVisibility, changePopupRenderFunc, addItemIdAtCartList} = this.props;
    const {catalogItem} = this.state;

    addItemIdAtCartList(catalogItem.id);

    changePopupVisibility(true);
    changePopupRenderFunc(() => (<PopupSuccessCatalog catalogItemId={catalogItem.id} />));
  }

  render() {
    return (
      <Popup
        className='popup-guitar-info'
        popupContentClass='popup-guitar-info__content'
      >
        <h2 className='popup-guitar-info__title'>Добавить товар в корзину</h2>
        <GuitarInfoBlock
          className='popup-guitar-info__info-block'
          catalogItem={this.state.catalogItem}
        />
        <ButtonAction
          className='popup-guitar-info__add-cart-button'
          title='Добавить в корзину'
          onClick={this.handleAddCartButtonClick}
        />
      </Popup>
    );
  }
}

PopupGuitarInfo.propTypes = {
  catalogItemId: PropTypes.string,
  allCatalogItems: catalogItemsType,
  changePopupVisibility: PropTypes.func,
  changePopupRenderFunc: PropTypes.func,
  addItemIdAtCartList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  allCatalogItems: getAllCatalogItems(state),
});

const mapDispatchToProps = (dispatch) => ({
  changePopupVisibility(isPopupVisible) {
    dispatch(Action[StoreNameSpace.POPUP].changePopupVisibility(isPopupVisible));
  },
  changePopupRenderFunc(popupRenderFunc) {
    dispatch(Action[StoreNameSpace.POPUP].changePopupRenderFunc(popupRenderFunc));
  },
  addItemIdAtCartList(catalogItemId) {
    dispatch(Action[StoreNameSpace.CART].addItemIdAtCartList(catalogItemId));
  },
});

export {PopupGuitarInfo};
export default connect(mapStateToProps, mapDispatchToProps)(PopupGuitarInfo);
