import React from 'react';
import PropTypes from 'prop-types';
import './checkbox.scss';

const Checkbox = ({title, addClass, id, name, disabled, checked, onChange}) => {
  const checkboxClasses = ['checkbox', addClass];

  if (checked) {
    checkboxClasses.push('checkbox--checked');
  }

  if (disabled) {
    checkboxClasses.push('checkbox--disabled');
  }

  return (
    <label className={checkboxClasses.join(' ').trim()}>
      <input
        className='checkbox__input'
        type='checkbox'
        name={name}
        data-id={id}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        data-testid='checkbox'
      />
      <span className='checkbox__content'>{title}</span>
    </label>
  );
};

Checkbox.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  addClass: PropTypes.string,
};

Checkbox.defaultProps = {
  addClass: '',
  disabled: false,
  checked: false,
};

export {Checkbox};
export default Checkbox;
