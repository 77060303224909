import {read as xlsxRead, utils as xlsxUtils} from 'xlsx';
import {createRubString} from '../utils';
import {
  GuitarTypeParamsObject,
  CART_ITEM_IDS_OBJECT_KEY,
  StoreNameSpace,
  PROMOCODE_VALUE_KEY,
} from '../constants';
import Action from '../store/root-action';

const loadSavedPromocodeValue = () => (dispatch) => {
  const savedPromocode = localStorage.getItem(PROMOCODE_VALUE_KEY);

  if (savedPromocode === null || savedPromocode === 'undefined') {
    return;
  }

  const savedPromocodeValue = String(savedPromocode).trim().toUpperCase();

  if (savedPromocodeValue.length > 0) {
    dispatch(Action[StoreNameSpace.CART].changePromocodeValue(savedPromocodeValue));
    dispatch(Action[StoreNameSpace.CART].changeLoadPromocodeStatus(true));
  }
};

const loadSavedCartItemIdsObject = (catalogItems) => (dispatch, _getState) => {
  if (!catalogItems || catalogItems.length === 0) {
    return;
  }

  const savedObject = JSON.parse(localStorage.getItem(CART_ITEM_IDS_OBJECT_KEY));

  if (!savedObject) {
    return;
  }

  const savedCartItemIdsObject = new Map(
    Object.entries(savedObject)
      .filter(([id, _number]) => catalogItems.find((item) => item.id === id))
  );

  dispatch(Action[StoreNameSpace.CART].loadCartItemIdsObjects(savedCartItemIdsObject));
};

const loadCatalogItems = () => (dispatch, _getState) => {
  fetch('/catalog.xlsx')
    .then((response) => response.arrayBuffer())
    .then((data) => {
      const workbook = xlsxRead(data, {
        type: 'binary',
      });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetLines = xlsxUtils.sheet_to_row_object_array(sheet, {header: 1});
      const catalogItemParams = JSON.parse(JSON.stringify(sheetLines)).slice(2);

      const guitarTypeIds = Object.keys(GuitarTypeParamsObject);

      const catalogItems = [];
      let minPrice = null;
      let maxPrice = null;

      catalogItemParams.forEach((itemParams) => {
        const [id, article, name, type, reviewsNumber, stringsAmount, price, , description] = itemParams;

        if (guitarTypeIds.includes(type)) {
          const priceTitle = createRubString(price);
          const imgSrc = require(`../img/catalog/${type}.png`);
          const imgSrcSet = require(`../img/catalog/${type}@2x.png`);

          if (minPrice === null || minPrice > price) {
            minPrice = price;
          }

          if (maxPrice === null || maxPrice < price) {
            maxPrice = price;
          }

          const correctStringsAmount = `${stringsAmount}`;
          const correctId = `${id}`;

          GuitarTypeParamsObject[type].stringsAmounts.add(correctStringsAmount);

          catalogItems.push({
            id: correctId,
            article,
            name,
            type,
            imgSrc,
            imgSrcSet,
            rate: Math.ceil(Math.random() * 5),
            stringsAmount: correctStringsAmount,
            price,
            priceTitle,
            description,
            reviewsNumber,
          });
        }
      });

      const StringsAmountsState = Array.from(
        new Set(
          Object.values(GuitarTypeParamsObject).map((guitarType) => Array.from(guitarType.stringsAmounts)).flat()
        )
      )
        .reduce((result, stringsAmount) => {
          result[stringsAmount] = {
            id: stringsAmount,
            checked: false,
            disabled: false,
          };

          return result;
        }, {});

      dispatch(Action[StoreNameSpace.CATALOG].loadCatalogItems(catalogItems));
      dispatch(Action[StoreNameSpace.FILTER].changeStringsAmountsState(StringsAmountsState));
      dispatch(Action[StoreNameSpace.FILTER].setInitMinPrice(minPrice));
      dispatch(Action[StoreNameSpace.FILTER].setInitMaxPrice(maxPrice));

      dispatch(loadSavedCartItemIdsObject(catalogItems));
      dispatch(loadSavedPromocodeValue());
    });
};

export {
  loadCatalogItems,
};
