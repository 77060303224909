import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {StoreNameSpace} from '../../constants';
import MainHeader from '../main-header/main-header';
import MainFooter from '../main-footer/main-footer';
import {breadcrumbsType} from '../../types';

const Page = ({
  headerClassName,
  pageClassName,
  title,
  children,
  breadcrumbs,
  footerClassName,
  isPopupVisible,
  popupRenderFunc,
}) => (
  <React.Fragment>
    <MainHeader addClass={headerClassName} title={title} breadcrumbs={breadcrumbs} />
    <main className={pageClassName} data-testid='main'>
      {children}
    </main>
    <MainFooter addClass={footerClassName} />
    {isPopupVisible ? (popupRenderFunc()) : null}
  </React.Fragment>
);

Page.propTypes = {
  headerClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  pageClassName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  breadcrumbs: breadcrumbsType,
  isPopupVisible: PropTypes.bool.isRequired,
  popupRenderFunc: PropTypes.func.isRequired,
};

Page.defaultProps = {
  breadcrumbs: [],
  popupRenderFunc: () => null,
};

const mapStateToProps = (state) => ({
  isPopupVisible: state[StoreNameSpace.POPUP].isPopupVisible,
  popupRenderFunc: state[StoreNameSpace.POPUP].popupRenderFunc,
});

export {Page};
export default connect(mapStateToProps)(Page);
