import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Popup from '../popup/popup';
import GuitarInfoBlock from '../guitar-info-block/guitar-info-block';
import ButtonAction from '../button-action/button-action';
import {getAllCatalogItems} from '../../store/selectors';
import Action from '../../store/root-action';
import {catalogItemsType} from '../../types';
import {StoreNameSpace} from '../../constants';
import './popup-delete-cart.scss';

class PopupDeleteCart extends React.Component {
  constructor(props) {
    super(props);

    const {catalogItemId, allCatalogItems} = props;

    this.state = {
      catalogItem: allCatalogItems.find((item) => catalogItemId === item.id),
    };

    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.handleCanselButtonClick = this.handleCanselButtonClick.bind(this);
  }

  handleDeleteButtonClick() {
    const {catalogItemId, deleteItemIdFromCartList, changePopupVisibility} = this.props;

    deleteItemIdFromCartList(catalogItemId);
    changePopupVisibility(false);
  }

  handleCanselButtonClick() {
    const {changePopupVisibility} = this.props;

    changePopupVisibility(false);
  }

  render() {
    return (
      <Popup
        className='popup-delete-cart'
        popupContentClass='popup-delete-cart__content'
      >
        <h2 className='popup-delete-cart__title'>Удалить этот товар?</h2>
        <GuitarInfoBlock
          className='popup-delete-cart__info-block'
          catalogItem={this.state.catalogItem}
        />
        <ButtonAction
          className='popup-delete-cart__delete-button'
          title='Удалить товар'
          onClick={this.handleDeleteButtonClick}
        />
        <ButtonAction
          className='popup-delete-cart__cansel-button'
          title='Продолжить покупки'
          onClick={this.handleCanselButtonClick}
          isGhostMode
        />
      </Popup>
    );
  }
}

PopupDeleteCart.propTypes = {
  catalogItemId: PropTypes.string,
  allCatalogItems: catalogItemsType,
  changePopupVisibility: PropTypes.func,
  deleteItemIdFromCartList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  allCatalogItems: getAllCatalogItems(state),
});

const mapDispatchToProps = (dispatch) => ({
  changePopupVisibility(isPopupVisible) {
    dispatch(Action[StoreNameSpace.POPUP].changePopupVisibility(isPopupVisible));
  },
  deleteItemIdFromCartList(isPopupVisible) {
    dispatch(Action[StoreNameSpace.CART].deleteItemIdFromCartList(isPopupVisible));
  },
});

export {PopupDeleteCart};
export default connect(mapStateToProps, mapDispatchToProps)(PopupDeleteCart);
