import {Key, FOCUS_ELEMENTS} from './constants';

const createFormatPriceString = (digit) => (
  String(digit).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
);

const createRubString = (digit, priceSuffix = '₽') => (
  `${createFormatPriceString(digit)} ${priceSuffix}`.trim()
);

const sortCatalogItemsById = (item1, item2) => {
  if (parseInt(item1.id, 10) > parseInt(item2.id, 10)) {
    return 1;
  }

  return -1;
};

const sortCatalogItemsByKey = (item1, item2, key) => {
  if (item1[key] > item2[key]) {
    return 1;
  }

  if (item1[key] < item2[key]) {
    return -1;
  }

  return sortCatalogItemsById(item1, item2);
};

const sortCatalogItemsByKeyAndDirection = (key, direction = 1) => (item1, item2) => (
  direction * sortCatalogItemsByKey(item1, item2, key)
);

const sortCatalogItemsByPrice = sortCatalogItemsByKeyAndDirection('price');
const sortCatalogItemsByPriceDesc = sortCatalogItemsByKeyAndDirection('price', -1);
const sortCatalogItemsByPopularity = sortCatalogItemsByKeyAndDirection('reviewsNumber');
const sortCatalogItemsByPopularityDesc = sortCatalogItemsByKeyAndDirection('reviewsNumber', -1);

const isEscKeyDown = (evt) => evt.key === Key.ESC;


const getFocusableElements = (container) => Array.from(
  container.querySelectorAll(FOCUS_ELEMENTS)
);

const getNextArrayIndex = (currentIndex, arr) => ((currentIndex + 1) % arr.length);

const getPreviousArrayIndex = (currentIndex, arr) => ((currentIndex + (arr.length - 1)) % arr.length);

export {
  createFormatPriceString,
  createRubString,
  sortCatalogItemsByPrice,
  sortCatalogItemsByPriceDesc,
  sortCatalogItemsByPopularity,
  sortCatalogItemsByPopularityDesc,
  isEscKeyDown,
  getFocusableElements,
  getNextArrayIndex,
  getPreviousArrayIndex,
};
