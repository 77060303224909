import React from 'react';
import {useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Popup from '../popup/popup';
import ButtonAction from '../button-action/button-action';
import Action from '../../store/root-action';
import {StoreNameSpace} from '../../constants';
import './popup-success-catalog.scss';

const PopupSuccessCatalog = (props) => {
  const {changePopupVisibility} = props;

  const navigate = useNavigate();

  const handleTransitionButtonClick = React.useCallback(
    () => {
      changePopupVisibility(false);
      navigate('/cart');
    },
    [changePopupVisibility, navigate]
  );

  const handleContinueButtonClick = React.useCallback(
    () => {
      changePopupVisibility(false);
    },
    [changePopupVisibility]
  );

  return (
    <Popup
      className='popup-success-catalog'
      popupContentClass='popup-success-catalog__content'
    >
      <h2 className='popup-success-catalog__title'>Товар успешно добавлен в корзину</h2>
      <ButtonAction
        className='popup-success-catalog__transition-button'
        title='Перейти в корзину'
        onClick={handleTransitionButtonClick}
      />
      <ButtonAction
        className='popup-success-catalog__continue-button'
        title='Продолжить покупки'
        onClick={handleContinueButtonClick}
        isGhostMode
      />
    </Popup>
  );
};

PopupSuccessCatalog.propTypes = {
  changePopupVisibility: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  changePopupVisibility(isPopupVisible) {
    dispatch(Action[StoreNameSpace.POPUP].changePopupVisibility(isPopupVisible));
  },
});

export {PopupSuccessCatalog};
export default connect(null, mapDispatchToProps)(PopupSuccessCatalog);
