import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import SortTypeButton from '../sort-type-button/sort-type-button';
import SortDirectionButton from '../sort-direction-button/sort-direction-button';
import {SortType, SortDirection} from '../../constants';
import {StoreNameSpace} from '../../constants';
import Action from '../../store/root-action';
import './sort.scss';

class Sort extends React.Component {
  constructor(props) {
    super(props);

    this.handleTypeSortTypeButtonClick = this.handleTypeSortTypeButtonClick.bind(this);
    this.handleSortDirectionButtonClick = this.handleSortDirectionButtonClick.bind(this);
  }

  handleTypeSortTypeButtonClick(evt) {
    const button = evt.target.closest('button');

    if (!button) {
      return;
    }

    const {sortDirection, changeSortType, changeSortDirection} = this.props;
    const newSortType = button.dataset['type'];

    if (Object.values(SortType).includes(newSortType)) {
      if (!Object.values(SortDirection).includes(sortDirection)) {
        changeSortDirection(SortDirection.ASC);
      }

      changeSortType(newSortType);
    }
  }

  handleSortDirectionButtonClick(evt) {
    const button = evt.target.closest('button');

    if (!button) {
      return;
    }

    const {sortType, changeSortType, changeSortDirection} = this.props;
    const newSortDirection = button.dataset['direction'];

    if (Object.values(SortDirection).includes(newSortDirection)) {
      if (!Object.values(SortType).includes(sortType)) {
        changeSortType(SortType.PRICE);
      }

      changeSortDirection(newSortDirection);
    }
  }

  render() {
    const {sortType, sortDirection} = this.props;

    return (
      <form
        className='sort'
        action='/sort'
        data-testid='sort'
      >
        <h2 className='sort__title'>Сортировать:</h2>
        <SortTypeButton
          addClass='sort__type-button'
          name={SortType.PRICE}
          isActive={SortType.PRICE === sortType}
          title='По цене'
          onSortButtonClick={this.handleTypeSortTypeButtonClick}
        />
        <SortTypeButton
          addClass='sort__type-button'
          name={SortType.POPYLARITY}
          isActive={SortType.POPYLARITY === sortType}
          title='По популярности'
          onSortButtonClick={this.handleTypeSortTypeButtonClick}
        />
        <SortDirectionButton
          addClass='sort__direction-button'
          name={SortDirection.ASC}
          isActive={SortDirection.ASC === sortDirection}
          title='По возрастанию'
          onDirectionButtonClick={this.handleSortDirectionButtonClick}
        />
        <SortDirectionButton
          addClass='sort__direction-button'
          name={SortDirection.DESC}
          isActive={SortDirection.DESC === sortDirection}
          title='По убыванию'
          onDirectionButtonClick={this.handleSortDirectionButtonClick}
        />
      </form>
    );
  }
}

Sort.propTypes = {
  sortType: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  changeSortType: PropTypes.func.isRequired,
  changeSortDirection: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sortType: state[StoreNameSpace.SORT].sortType,
  sortDirection: state[StoreNameSpace.SORT].sortDirection,
});

const mapDispatchToProps = (dispatch) => ({
  changeSortType(sortType) {
    dispatch(Action[StoreNameSpace.SORT].changeSortType(sortType));
  },
  changeSortDirection(sortDirection) {
    dispatch(Action[StoreNameSpace.SORT].changeSortDirection(sortDirection));
  },
});

export {Sort};
export default connect(mapStateToProps, mapDispatchToProps)(Sort);
