import {configureStore} from '@reduxjs/toolkit';
import reducer from './root-reducer';
import {loadCatalogItems} from '../middlewares/thunk';

const createStore = () => {
  const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
  });

  store.dispatch(loadCatalogItems());

  return store;
};

export {
  createStore,
};
