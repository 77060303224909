import React from 'react';
import PropTypes from 'prop-types';
import './button-close.scss';

const ButtonClose = ({title, className, type, ariaLabel, onClick}) => {
  const classNames = [className, 'button-close'];

  return (
    <button
      className={classNames.join(' ').trim()}
      type={type}
      onClick={onClick}
      aria-label={ariaLabel}
      data-testid='button-close'
    >
      {title}
    </button>
  );
};

ButtonClose.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonClose.defaultProps = {
  title: '',
  className: '',
  type: 'button',
};

export default ButtonClose;
