const ActionType = {
  CHANGE_SORT_TYPE: 'CHANGE_SORT_TYPE',
  CHANGE_SORT_DIRECTION: 'CHANGE_SORT_DIRECTION',
};

const Action = {
  changeSortType: (sortType) => ({
    type: ActionType.CHANGE_SORT_TYPE,
    payload: sortType,
  }),
  changeSortDirection: (maxPrice) => ({
    type: ActionType.CHANGE_SORT_DIRECTION,
    payload: maxPrice,
  }),
};


export {
  ActionType,
  Action,
};
