const ActionType = {
  LOAD_CATALOG_ITEMS: 'LOAD_CATALOG_ITEMS',
  CHANGE_CURRENT_PAGE: 'CHANGE_CURRENT_PAGE',
};

const Action = {
  loadCatalogItems: (catalogItems) => ({
    type: ActionType.LOAD_CATALOG_ITEMS,
    payload: catalogItems,
  }),
  changeCurrentPage: (currentPage) => ({
    type: ActionType.CHANGE_CURRENT_PAGE,
    payload: currentPage,
  }),
};

export {
  ActionType,
  Action,
};
