import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Checkbox from '../checkbox/checkbox';
import {StoreNameSpace} from '../../constants';
import Action from '../../store/root-action';
import {createFormatPriceString} from '../../utils';
import {GuitarTypeParamsObject} from '../../constants';
import {stringsAmountsStateType} from '../../types';
import './filter.scss';

class Filter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      minPrice: props.minPrice,
      maxPrice: props.maxPrice,
    };

    this.minPriceRef = React.createRef();
    this.maxPriceRef = React.createRef();

    this.handleMinPriceInputChange = this.handleMinPriceInputChange.bind(this);
    this.handleMaxPriceInputChange = this.handleMaxPriceInputChange.bind(this);
    this.handleMinPriceInputBlur = this.handleMinPriceInputBlur.bind(this);
    this.handleMaxPriceInputBlur = this.handleMaxPriceInputBlur.bind(this);
    this.hangleGuitarTypeChange = this.hangleGuitarTypeChange.bind(this);
    this.handleStringsAmountState = this.handleStringsAmountState.bind(this);
  }

  isNotCorrectDigit(value) {
    return isNaN(value) || value < 0;
  }

  getCorrectMinPrice(value) {
    const {maxPrice, initMinPrice, initMaxPrice} = this.props;

    const maxPriceDigit = parseInt(maxPrice, 10);
    let newValue = parseInt(value, 10);

    if (this.isNotCorrectDigit(newValue)) {
      newValue = '';
    } else if (newValue < initMinPrice) {
      newValue = initMinPrice;
    } else if (!isNaN(maxPriceDigit) && newValue > maxPriceDigit) {
      newValue = maxPriceDigit;
    } else if (newValue > initMaxPrice) {
      newValue = initMaxPrice;
    }

    return newValue;
  }

  getCorrectMaxPrice(value) {
    const {minPrice, initMinPrice, initMaxPrice} = this.props;

    const minPriceDigit = parseInt(minPrice, 10);
    let newValue = parseInt(value, 10);

    if (this.isNotCorrectDigit(newValue)) {
      newValue = '';
    } else if (newValue > initMaxPrice) {
      newValue = initMaxPrice;
    } else if (!isNaN(minPriceDigit) && newValue < minPriceDigit) {
      newValue = minPriceDigit;
    } else if (newValue < initMinPrice) {
      newValue = initMinPrice;
    }

    return newValue;
  }

  handleMinPriceInputChange(evt) {
    const {changeMinPrice} = this.props;

    const newValue = evt.target.valueAsNumber;

    if (this.isNotCorrectDigit(newValue)) {
      this.setState({minPrice: ''});
    } else {
      changeMinPrice(this.getCorrectMinPrice(newValue));
      this.setState({minPrice: newValue});
    }
  }

  handleMaxPriceInputChange(evt) {
    const {changeMaxPrice} = this.props;

    const newValue = evt.target.valueAsNumber;

    if (this.isNotCorrectDigit(newValue)) {
      this.setState({maxPrice: ''});
    } else {
      changeMaxPrice(this.getCorrectMaxPrice(newValue));
      this.setState({maxPrice: newValue});
    }
  }

  handleMinPriceInputBlur() {
    const {changeMinPrice} = this.props;
    const {minPrice} = this.state;

    const newValue = this.getCorrectMinPrice(minPrice);

    this.setState({minPrice: newValue});
    changeMinPrice(newValue);

    if (isNaN(this.minPriceRef.current.valueAsNumber)) {
      this.minPriceRef.current.value = '';
    }
  }

  handleMaxPriceInputBlur() {
    const {changeMaxPrice} = this.props;
    const {maxPrice} = this.state;

    const newValue = this.getCorrectMaxPrice(maxPrice);

    this.setState({maxPrice: newValue});
    changeMaxPrice(newValue);

    if (isNaN(this.maxPriceRef.current.valueAsNumber)) {
      this.maxPriceRef.current.value = '';
    }
  }

  hangleGuitarTypeChange(evt) {
    const {
      checkedGuitarTypes,
      changeCheckedGuitarTypes,
      StringsAmountsState,
      changeStringsAmountsState,
    } = this.props;
    const newGuitarTypeId = evt.target?.dataset?.id;

    if (newGuitarTypeId) {
      const guitarTypeParamsObject = GuitarTypeParamsObject[newGuitarTypeId];

      if (guitarTypeParamsObject) {
        let newCheckedGuitarTypes = [...checkedGuitarTypes];

        if (newCheckedGuitarTypes.includes(guitarTypeParamsObject.id)) {
          newCheckedGuitarTypes = newCheckedGuitarTypes.filter((newCheckedGuitarType) => !(newCheckedGuitarType === guitarTypeParamsObject.id));
        } else {
          newCheckedGuitarTypes.push(guitarTypeParamsObject.id);
        }

        const newStringsAmountsState = {};

        if (newCheckedGuitarTypes.length === 0) {
          Object.entries(StringsAmountsState).forEach(([id, stringsAmountObject]) => {
            newStringsAmountsState[id] = {
              ...stringsAmountObject,
              disabled: false,
            };
          });
        } else {
          let availableStringsAmounts = [];

          newCheckedGuitarTypes.forEach((checkedGuitarType) => {
            availableStringsAmounts.push(Array.from(GuitarTypeParamsObject[checkedGuitarType].stringsAmounts));
          });

          availableStringsAmounts = new Set(availableStringsAmounts.flat());

          Object.entries(StringsAmountsState).forEach(([id, stringsAmountObject]) => {
            const isAvailableStringsAmount = availableStringsAmounts.has(id);

            newStringsAmountsState[id] = {
              ...stringsAmountObject,
              checked: isAvailableStringsAmount ? stringsAmountObject.checked : false,
              disabled: !isAvailableStringsAmount,
            };
          });
        }

        changeCheckedGuitarTypes(newCheckedGuitarTypes);
        changeStringsAmountsState(newStringsAmountsState);
      }
    }
  }

  handleStringsAmountState(evt) {
    const {StringsAmountsState, changeStringsAmountsState} = this.props;

    const newStringsAmountId = evt.target?.dataset?.id;

    if (StringsAmountsState[newStringsAmountId]) {
      const newStringsAmountsState = {...StringsAmountsState};

      newStringsAmountsState[newStringsAmountId] = {
        ...StringsAmountsState[newStringsAmountId],
        checked: !StringsAmountsState[newStringsAmountId].checked,
      };

      changeStringsAmountsState(newStringsAmountsState);
    }
  }

  render() {
    const {
      initMinPrice,
      initMaxPrice,
      checkedGuitarTypes,
      StringsAmountsState,
    } = this.props;

    const {minPrice, maxPrice} = this.state;

    return (
      <form
        className='filter'
        action='/filter'
        data-testid='filter'
      >
        <h2 className='filter__title'>Фильтр</h2>
        <fieldset className='filter__block filter__block--price'>
          <legend className='filter__block-title'>Цена, ₽</legend>
          <input
            className='filter__input filter__input--min'
            type='number'
            value={minPrice}
            onChange={this.handleMinPriceInputChange}
            onBlur={this.handleMinPriceInputBlur}
            placeholder={createFormatPriceString(initMinPrice)}
            data-testid='filter__input--min'
            min={initMinPrice}
            max={initMaxPrice}
            ref={this.minPriceRef}
          />
          <input
            className='filter__input filter__input--max'
            type='number'
            value={maxPrice}
            onChange={this.handleMaxPriceInputChange}
            onBlur={this.handleMaxPriceInputBlur}
            placeholder={createFormatPriceString(initMaxPrice)}
            data-testid='filter__input--max'
            min={initMinPrice}
            max={initMaxPrice}
            ref={this.maxPriceRef}
          />
        </fieldset>
        <fieldset className='filter__block filter__block--guitar-type'>
          <legend className='filter__block-title'>Тип гитар</legend>
          {Object.values(GuitarTypeParamsObject).map(({title, id}) => (
            <Checkbox
              addClass='filter__checkbox'
              key={id}
              id={id}
              title={title}
              name='guitarTypes'
              onChange={this.hangleGuitarTypeChange}
              checked={checkedGuitarTypes.includes(id)}
            />
          ))}
        </fieldset>
        <fieldset className='filter__block filter__block--string-numbers'>
          <legend className='filter__block-title'>Количество струн</legend>
          {Object.values(StringsAmountsState).map(({id, checked, disabled}) => (
            <Checkbox
              addClass='filter__checkbox'
              key={id}
              id={id}
              title={id}
              name='stringsAmounts'
              checked={checked}
              disabled={disabled}
              onChange={this.handleStringsAmountState}
            />
          ))}
        </fieldset>
      </form>
    );
  }
}

Filter.propTypes = {
  minPrice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  maxPrice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  initMinPrice: PropTypes.number.isRequired,
  initMaxPrice: PropTypes.number.isRequired,
  changeMinPrice: PropTypes.func.isRequired,
  changeMaxPrice: PropTypes.func.isRequired,
  checkedGuitarTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  changeCheckedGuitarTypes: PropTypes.func.isRequired,
  StringsAmountsState: stringsAmountsStateType,
  changeStringsAmountsState: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  initMinPrice: state[StoreNameSpace.FILTER].initMinPrice,
  initMaxPrice: state[StoreNameSpace.FILTER].initMaxPrice,
  StringsAmountsState: state[StoreNameSpace.FILTER].StringsAmountsState,
  minPrice: state[StoreNameSpace.FILTER].minPrice,
  maxPrice: state[StoreNameSpace.FILTER].maxPrice,
  checkedGuitarTypes: state[StoreNameSpace.FILTER].checkedGuitarTypes,
});

const mapDispatchToProps = (dispatch) => ({
  changeMinPrice(minPrice) {
    dispatch(Action[StoreNameSpace.FILTER].changeMinPrice(minPrice));
    dispatch(Action[StoreNameSpace.CATALOG].changeCurrentPage(1));
  },
  changeMaxPrice(maxPrice) {
    dispatch(Action[StoreNameSpace.FILTER].changeMaxPrice(maxPrice));
    dispatch(Action[StoreNameSpace.CATALOG].changeCurrentPage(1));
  },
  changeCheckedGuitarTypes(checkedGuitarTypes) {
    dispatch(Action[StoreNameSpace.FILTER].changeCheckedGuitarTypes(checkedGuitarTypes));
    dispatch(Action[StoreNameSpace.CATALOG].changeCurrentPage(1));
  },
  changeStringsAmountsState(StringsAmountsState) {
    dispatch(Action[StoreNameSpace.FILTER].changeStringsAmountsState(StringsAmountsState));
    dispatch(Action[StoreNameSpace.CATALOG].changeCurrentPage(1));
  },
});

export {Filter};
export default connect(mapStateToProps, mapDispatchToProps)(Filter);
