import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {ReactComponent as LogoSvg} from '../../img/logo.svg';
import './logo-link.scss';

const LOGO_LINK_CLASS = 'logo-link';
const LOGO_LINK_ACTIVE_CLASSES_GROUP = `${LOGO_LINK_CLASS} ${LOGO_LINK_CLASS}--current`;

const LOGO_LINK = '/';

const LogoLink = ({addClass}) => (
  <NavLink
    className={({isActive}) => [addClass, isActive ? LOGO_LINK_ACTIVE_CLASSES_GROUP : LOGO_LINK_CLASS].join(' ').trim()}
    to={LOGO_LINK}
    tabIndex={window.location.pathname === LOGO_LINK ? -1 : null}
    aria-label='Guitar-shop'
    data-testid='logo-link'
  >
    <LogoSvg />
  </NavLink>
);


LogoLink.propTypes = {
  addClass: PropTypes.string,
};

LogoLink.defaultProps = {
  addClass: '',
};

export {LogoLink};
export default LogoLink;
