import React from 'react';
import PropTypes from 'prop-types';
import './button-action-small.scss';

const ButtonActionSmall = ({title, addClass, onClick}) => {
  const classNames = [addClass, 'button-action-small'];

  return (
    <button
      className={classNames.join(' ').trim()}
      type='button'
      onClick={onClick}
      data-testid='button-action-small'
    >
      {title}
    </button>
  );
};

ButtonActionSmall.propTypes = {
  title: PropTypes.string.isRequired,
  addClass: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonActionSmall.defaultProps = {
  addClass: '',
};

export default ButtonActionSmall;
