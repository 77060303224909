import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ButtonAction from '../button-action/button-action';
import Action from '../../store/root-action';
import {getFullCartPrice} from '../../store/selectors';
import {createRubString, createFormatPriceString} from '../../utils';
import {StoreNameSpace, PROMOCODE_VALUE_KEY} from '../../constants';
import './cart-order-panel.scss';

const Discount = {
  GITARAHIT: {
    id: 'GITARAHIT',
    modifyPrice(fullPrice) {
      return fullPrice * this.discountValue;
    },
    discountValue: 0.9,
  },
  SUPERGITARA: {
    id: 'SUPERGITARA',
    modifyPrice(fullPrice) {
      return fullPrice > this.discountValue ? fullPrice - this.discountValue : 0;
    },
    discountValue: 700,
  },
  GITARA2020: {
    id: 'GITARA2020',
    modifyPrice(fullPrice) {
      const maxDiscountPrice = fullPrice * this.discountRestrict;

      return this.discountValue > maxDiscountPrice
        ? fullPrice - maxDiscountPrice
        : fullPrice - this.discountValue;
    },
    discountValue: 3000,
    discountRestrict: 0.3,
  },
};

const DISCOUNT_INFO_DEFAULT_CLASS = 'cart-order-panel__discount-info';

const DiscountInfo = {
  DEFAULT: {
    id: 'DEFAULT',
    text: 'Введите свой промокод, если он у вас есть.',
    className: DISCOUNT_INFO_DEFAULT_CLASS,
  },
  ERROR: {
    id: 'ERROR',
    text: 'Промокод не действителен',
    className: `${DISCOUNT_INFO_DEFAULT_CLASS} ${DISCOUNT_INFO_DEFAULT_CLASS}--error`,
  },
  SUCCESS: {
    id: 'SUCCESS',
    text: 'Промокод применён',
    className: `${DISCOUNT_INFO_DEFAULT_CLASS} ${DISCOUNT_INFO_DEFAULT_CLASS}--success`,
  },
};

class CartOrderPanel extends React.Component {
  constructor(props) {
    super(props);

    const {promocodeValue} = props;

    const newDiscountInfoStatus = this.getDiscountInfoStatus(promocodeValue);

    this.state = {
      discountInfoStatus: newDiscountInfoStatus,
      modifyPrice: this.getModifyPriceFunction(newDiscountInfoStatus, promocodeValue),
    };

    this.discountInputRef = React.createRef();

    this.handleDiscountApplyButtonClick = this.handleDiscountApplyButtonClick.bind(this);
    this.handlePromocodeChange = this.handlePromocodeChange.bind(this);
  }

  componentDidUpdate() {
    const {promocodeValue, isLoadPromocode, changeLoadPromocodeStatus} = this.props;

    if (isLoadPromocode) {
      const {discountInfoStatus} = this.state;

      const newDiscountInfoStatus = this.getDiscountInfoStatus(promocodeValue);

      if (discountInfoStatus !== newDiscountInfoStatus) {
        const newModifyPriceFunction = this.getModifyPriceFunction(newDiscountInfoStatus, promocodeValue);

        this.setState({
          discountInfoStatus: newDiscountInfoStatus,
          modifyPrice: newModifyPriceFunction,
        });
      }

      changeLoadPromocodeStatus(false);
    }
  }

  getDiscountInfoStatus(promocodeValue) {
    if (promocodeValue.length === 0) {
      return DiscountInfo.DEFAULT;
    }

    if (Discount[promocodeValue]) {
      return DiscountInfo.SUCCESS;
    }

    return DiscountInfo.ERROR;
  }

  getModifyPriceFunction(discountInfoStatus, promocodeValue) {
    if (discountInfoStatus === DiscountInfo.SUCCESS && Discount[promocodeValue]) {
      return Discount[promocodeValue].modifyPrice.bind(Discount[promocodeValue]);
    }

    return (fullCartPrice) => fullCartPrice;
  }

  getPromocodeValue() {
    return String(this.discountInputRef.current.value).toUpperCase();
  }

  handlePromocodeChange() {
    const {changePromocodeValue} = this.props;

    const promocodeValue = this.getPromocodeValue();

    changePromocodeValue(promocodeValue);
  }

  handleDiscountApplyButtonClick(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const userDicount = this.getPromocodeValue().toUpperCase();

    const newDiscountInfoStatus = this.getDiscountInfoStatus(userDicount);
    const newModifyPriceFunction = this.getModifyPriceFunction(newDiscountInfoStatus, userDicount);

    if (newDiscountInfoStatus === DiscountInfo.SUCCESS) {
      localStorage.setItem(PROMOCODE_VALUE_KEY, userDicount);
    } else {
      localStorage.setItem(PROMOCODE_VALUE_KEY, '');
    }

    this.setState({
      discountInfoStatus: newDiscountInfoStatus,
      modifyPrice: newModifyPriceFunction,
    });
  }

  render() {
    const {fullCartPrice, promocodeValue} = this.props;
    const {discountInfoStatus, modifyPrice} = this.state;

    return (
      <section
        className='cart-order-panel'
        data-testid='cart-order-panel'
      >
        <h2 className='cart-order-panel__title'>Итоговая стоимать заказа</h2>
        <p className='cart-order-panel__discount-title'>Промокод на скидку</p>
        <p className={discountInfoStatus.className}>{discountInfoStatus.text}</p>
        <form className='cart-order-panel__discount-form' action='/discount'>
          <input
            className='cart-order-panel__discount-input'
            ref={this.discountInputRef}
            type='text'
            placeholder='code'
            value={promocodeValue}
            onChange={this.handlePromocodeChange}
            data-testid={'cart-order-panel__discount-input'}
          />
          <ButtonAction
            className='cart-order-panel__discount-apply-button'
            title='Применить купон'
            type='submit'
            onClick={this.handleDiscountApplyButtonClick}
          />
        </form>
        {
          discountInfoStatus === DiscountInfo.SUCCESS && fullCartPrice > 0
            ? (
              <p className='cart-order-panel__total-price'>
                Всего <span>{createFormatPriceString(fullCartPrice)}</span> {createRubString(modifyPrice(fullCartPrice))}
              </p>
            )
            : (
              <p className='cart-order-panel__total-price'>
                Всего {createRubString(fullCartPrice)}
              </p>
            )
        }
        <Link
          className='cart-order-panel__submit-order-button button-action'
          to='/success'
        >
          Оформить заказ
        </Link>
      </section>
    );
  }
}

CartOrderPanel.propTypes = {
  fullCartPrice: PropTypes.number,
  promocodeValue: PropTypes.string,
  changePromocodeValue: PropTypes.func,
  isLoadPromocode: PropTypes.bool,
  changeLoadPromocodeStatus: PropTypes.func,
};

const mapStateToProps = (state) => ({
  fullCartPrice: getFullCartPrice(state),
  promocodeValue: state[StoreNameSpace.CART].promocodeValue,
  isLoadPromocode: state[StoreNameSpace.CART].isLoadPromocode,
});

const mapDispatchToProps = (dispatch) => ({
  changePromocodeValue(promocodeValue) {
    dispatch(Action[StoreNameSpace.CART].changePromocodeValue(promocodeValue));
  },
  changeLoadPromocodeStatus(promocodeStatus) {
    dispatch(Action[StoreNameSpace.CART].changeLoadPromocodeStatus(promocodeStatus));
  },
});

export {CartOrderPanel};
export default connect(mapStateToProps, mapDispatchToProps)(CartOrderPanel);
