import {ActionType} from '../actions/catalog';

const initialState = {
  catalogItems: [],
  currentPage: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOAD_CATALOG_ITEMS:
      return {
        ...state,
        ...{catalogItems: action.payload},
      };
    case ActionType.CHANGE_CURRENT_PAGE:
      return {
        ...state,
        ...{currentPage: action.payload},
      };
    default:
      return state;
  }
};

export default reducer;
