import {ActionType} from '../actions/sort';

const initialState = {
  sortType: '',
  sortDirection: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.CHANGE_SORT_TYPE:
      return {
        ...state,
        ...{sortType: action.payload},
      };
    case ActionType.CHANGE_SORT_DIRECTION:
      return {
        ...state,
        ...{sortDirection: action.payload},
      };
    default:
      return state;
  }
};

export default reducer;
