import {ActionType} from '../actions/popup';

const initialState = {
  isPopupVisible: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.CHANGE_POPUP_RENDER_FUNC:
      return {
        ...state,
        ...{popupRenderFunc: action.payload},
      };
    case ActionType.CHANGE_POPUP_VISIBILITY:
      return {
        ...state,
        ...{isPopupVisible: action.payload},
      };
    default:
      return state;
  }
};

export default reducer;
