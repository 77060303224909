import React from 'react';
import {Link} from 'react-router-dom';
import Page from '../page/page';
import './page-404.scss';

const Pages = [
  {
    title: 'Главная',
    href: '/',
  },
  {
    title: 'Корзина',
    href: '/cart',
  },
];

const Page404 = () => (
  <Page pageClassName='page-404' title='Страница не найдена'>
    <h2
      className='page-404__title'
      data-testid='page-404__title'
    >
      В проекте есть 2 страницы:
    </h2>
    <ul
      className='page-404__pages-list'
      data-testid='page-404__pages-list'
    >
      {Pages.map(({title, href}) => (
        <li className='page-404__page-wrapper' key={href}>
          <Link className='page-404__page-link' to={href}>
            {title}
          </Link>
        </li>
      ))}
    </ul>
  </Page>
);

export {Page404};
export default Page404;
