import {ActionType} from '../actions/filter';

const initialState = {
  initMinPrice: 1000,
  initMaxPrice: 30000,
  minPrice: '',
  maxPrice: '',
  checkedGuitarTypes: [],
  StringsAmountsState: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.CHANGE_MIN_PRICE:
      return {
        ...state,
        ...{minPrice: action.payload},
      };
    case ActionType.CHANGE_MAX_PRICE:
      return {
        ...state,
        ...{maxPrice: action.payload},
      };
    case ActionType.CHANGE_CHECKED_GUITAR_TYPES:
      return {
        ...state,
        ...{checkedGuitarTypes: action.payload},
      };
    case ActionType.CHANGE_STRINGS_AMOUNTS_STATE:
      return {
        ...state,
        ...{StringsAmountsState: action.payload},
      };
    case ActionType.SET_INIT_MIN_PRICE:
      return {
        ...state,
        ...{initMinPrice: action.payload},
      };
    case ActionType.SET_INIT_MAX_PRICE:
      return {
        ...state,
        ...{initMaxPrice: action.payload},
      };
    default:
      return state;
  }
};

export default reducer;
