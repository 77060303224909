import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import './main-footer-block-link-wrapper.scss';

const BLOCK_LINK_CLASS = 'main-footer__block-link';
const BLOCK_LINK_ACTIVE_CLASSES_GROUP = `${BLOCK_LINK_CLASS} ${BLOCK_LINK_CLASS}--current`;

const MainFooterBlockLinkWrapper = ({title, href}) => (
  <li
    className='main-footer__block-link-wrapper'
    data-testid='main-footer__block-link-wrapper'
  >
    <NavLink
      className={({isActive}) => isActive ? BLOCK_LINK_ACTIVE_CLASSES_GROUP : BLOCK_LINK_CLASS}
      to={href}
      tabIndex={window.location.pathname === href ? -1 : null}
    >
      {title}
    </NavLink>
  </li>
);

MainFooterBlockLinkWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export {MainFooterBlockLinkWrapper};
export default MainFooterBlockLinkWrapper;
