import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import CatalogPageButton from '../catalog-page-button/catalog-page-button';
import CatalogItem from '../catalog-item/catalog-item';
import {StoreNameSpace} from '../../constants';
import Action from '../../store/root-action';
import {
  getSortedCatalogItems,
  getPagesNumber,
  getCatalogItemIds,
} from '../../store/selectors';
import {NUMBER_CATALOG_ITEMS_ON_PAGE} from '../../constants';
import {catalogItemsType} from '../../types';
import './catalog.scss';

const CatalogPageButtonMode = {
  NEXT: 'NEXT',
  PREV: 'PREV',
};

class Catalog extends React.Component {
  constructor(props) {
    super(props);

    this.handleCatalogPageButtonClick = this.handleCatalogPageButtonClick.bind(this);
  }

  handleCatalogPageButtonClick(evt) {
    const button = evt.target.closest('button');

    if (!button) {
      return;
    }

    const {currentPage, pagesNumber, changeCurrentPage} = this.props;
    const buttonMode = button.dataset['mode'];

    if (CatalogPageButtonMode[buttonMode]) {
      if (CatalogPageButtonMode[buttonMode] === CatalogPageButtonMode.NEXT) {
        changeCurrentPage(currentPage + 1);
      } else if (CatalogPageButtonMode[buttonMode] === CatalogPageButtonMode.PREV) {
        changeCurrentPage(currentPage - 1);
      }
    } else {
      const newPageNumber = parseInt(button.textContent, 10);

      if (!isNaN(newPageNumber) && newPageNumber >= 1 && newPageNumber <= pagesNumber) {
        changeCurrentPage(newPageNumber);
      }
    }
  }

  render() {
    const {catalogItems, pagesNumber, currentPage} = this.props;

    const startCatalogItemsIndex = (NUMBER_CATALOG_ITEMS_ON_PAGE * currentPage - NUMBER_CATALOG_ITEMS_ON_PAGE);
    const endCatalogItemsIndex = NUMBER_CATALOG_ITEMS_ON_PAGE * currentPage;

    const isNextPageButtonVisible = pagesNumber > 1 && currentPage < pagesNumber;
    const isPrevPageButtonVisible = currentPage > 1;
    const isSidePagesButtonVisible = pagesNumber > 1;
    const isAnotherPageButtonVisible = pagesNumber > 2;
    let anotherPageButtonTitle = currentPage;

    if (currentPage === 1) {
      anotherPageButtonTitle = currentPage + 1;
    } else if (currentPage === pagesNumber) {
      anotherPageButtonTitle = currentPage - 1;
    }

    return (
      <section
        className='catalog'
        data-testid='catalog'
      >
        <h2 className='catalog__title'>Список товаров</h2>
        {
          catalogItems.length === 0
            ? (
              <p className='catalog__info' data-testid='catalog__info'>
                Подходящих товаров не найдено
              </p>
            )
            : (
              <ul className='catalog__items-list' data-testid='catalog__items-list'>
                {catalogItems.slice(startCatalogItemsIndex, endCatalogItemsIndex).map((item) => (
                  <CatalogItem key={item.id} catalogItem={item} />
                ))}
              </ul>
            )
        }
        <ul className='catalog__page-list'>
          {
            isPrevPageButtonVisible
              ? (
                <CatalogPageButton
                  title='Назад'
                  isTextButton
                  onClick={this.handleCatalogPageButtonClick}
                  mode={CatalogPageButtonMode.PREV}
                />
              )
              : null
          }
          {
            isSidePagesButtonVisible
              ? (
                <CatalogPageButton
                  title='1'
                  isActive={currentPage === 1}
                  onClick={this.handleCatalogPageButtonClick}
                />
              )
              : null
          }
          {
            isAnotherPageButtonVisible
              ? (
                <CatalogPageButton
                  title={`${anotherPageButtonTitle}`}
                  isActive={anotherPageButtonTitle === currentPage}
                  onClick={this.handleCatalogPageButtonClick}
                />
              )
              : null
          }
          {
            isSidePagesButtonVisible
              ? (
                <CatalogPageButton
                  title={`${pagesNumber}`}
                  isActive={currentPage === pagesNumber}
                  onClick={this.handleCatalogPageButtonClick}
                />
              )
              : null
          }
          {
            isNextPageButtonVisible
              ? (
                <CatalogPageButton
                  title='Далее'
                  isTextButton
                  onClick={this.handleCatalogPageButtonClick}
                  mode={CatalogPageButtonMode.NEXT}
                />
              )
              : null
          }
        </ul>
      </section>
    );
  }
}

Catalog.propTypes = {
  catalogItems: catalogItemsType.isRequired,
  pagesNumber: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  changeCurrentPage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  catalogItemIds: getCatalogItemIds(state),
  catalogItems: getSortedCatalogItems(state),
  pagesNumber: getPagesNumber(state),
  currentPage: state[StoreNameSpace.CATALOG].currentPage,
});

const mapDispatchToProps = (dispatch) => ({
  changeCurrentPage(currentPage) {
    dispatch(Action[StoreNameSpace.CATALOG].changeCurrentPage(currentPage));
  },
  changePopupVisibility(isPopupVisible) {
    dispatch(Action[StoreNameSpace.POPUP].changePopupVisibility(isPopupVisible));
  },
  changePopupRenderFunc(popupRenderFunc) {
    dispatch(Action[StoreNameSpace.POPUP].changePopupRenderFunc(popupRenderFunc));
  },
});

export {Catalog};
export default connect(mapStateToProps, mapDispatchToProps)(Catalog);
