import React from 'react';
import PropTypes from 'prop-types';
import './main-title.scss';

const MainTitle = ({title}) => (
  <h1
    className='main-title'
    data-testid='main-title'
  >
    {title}
  </h1>
);

MainTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export {MainTitle};
export default MainTitle;
