import React from 'react';
import PropTypes from 'prop-types';
import './sort-type-button.scss';

const DEFAULT_CLASS = 'sort-type-button';
const ACTIVE_CLASS = `${DEFAULT_CLASS}--active`;

const SortTypeButton = ({name, title, addClass, isActive, onSortButtonClick}) => {
  const classNames = [addClass, DEFAULT_CLASS, `${DEFAULT_CLASS}--${name}`];

  if (isActive) {
    classNames.push(ACTIVE_CLASS);
  }

  return (
    <button
      className={classNames.join(' ').trim()}
      type='button'
      data-type={name}
      onClick={onSortButtonClick}
      data-testid='sort-type-button'
    >
      {title}
    </button>
  );
};

SortTypeButton.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  addClass: PropTypes.string,
  isActive: PropTypes.bool,
  onSortButtonClick: PropTypes.func,
};

SortTypeButton.defaultProps = {
  addClass: '',
};

export {SortTypeButton};
export default SortTypeButton;
