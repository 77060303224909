import {createSelector} from 'reselect';
import {
  sortCatalogItemsByPrice,
  sortCatalogItemsByPriceDesc,
  sortCatalogItemsByPopularity,
  sortCatalogItemsByPopularityDesc,
} from '../utils';
import {
  SortType,
  SortDirection,
  NUMBER_CATALOG_ITEMS_ON_PAGE,
  StoreNameSpace,
} from '../constants';

const getAllCatalogItems = (state) => state[StoreNameSpace.CATALOG].catalogItems;
const getMinPriceDitit = (state) => parseInt(state[StoreNameSpace.FILTER].minPrice, 10);
const getMaxPriceDigit = (state) => parseInt(state[StoreNameSpace.FILTER].maxPrice, 10);
const getCheckedGuitarTypes = (state) => state[StoreNameSpace.FILTER].checkedGuitarTypes;
const getStringsAmountsState = (state) => state[StoreNameSpace.FILTER].StringsAmountsState;
const getSortType = (state) => state[StoreNameSpace.SORT].sortType;
const getSortDirection = (state) => state[StoreNameSpace.SORT].sortDirection;
const getCartItemIdsObject = (state) => state[StoreNameSpace.CART].cartItemIdsObject;

const getCheckedStringsAmounts = createSelector(
  [getStringsAmountsState],
  (stringsAmountsObject) => (
    Object.values(stringsAmountsObject).map((stringsAmountObject) => (
      stringsAmountObject.checked === true ? stringsAmountObject.id : null
    ))
      .filter((id) => id !== null)
  )
);

const getFilteredCatalogItems = createSelector(
  [
    getAllCatalogItems,
    getMinPriceDitit,
    getMaxPriceDigit,
    getCheckedGuitarTypes,
    getCheckedStringsAmounts,
  ],
  (
    catalogItems,
    minPrice,
    maxPrice,
    guitarTypes,
    checkedStringsAmounts
  ) => (
    catalogItems.filter((catalogItem) => {
      if (!isNaN(minPrice) && catalogItem.price < minPrice) {
        return false;
      }

      if (!isNaN(maxPrice) && catalogItem.price > maxPrice) {
        return false;
      }

      if (guitarTypes.length > 0 && !(guitarTypes.includes(catalogItem.type))) {
        return false;
      }

      if (checkedStringsAmounts.length > 0 && !(checkedStringsAmounts.includes(catalogItem.stringsAmount))) {
        return false;
      }

      return true;
    })
  ),
);

const getSortedCatalogItems = createSelector(
  [getFilteredCatalogItems, getSortType, getSortDirection],
  (catalogItem, type, direction) => {
    if (type !== '' && direction !== '') {
      const sortedCatalogItems = [...catalogItem];

      if (type === SortType.PRICE) {
        if (direction === SortDirection.ASC) {
          sortedCatalogItems.sort(sortCatalogItemsByPrice);
        } else if (direction === SortDirection.DESC) {
          sortedCatalogItems.sort(sortCatalogItemsByPriceDesc);
        }
      } else if (type === SortType.POPYLARITY) {
        if (direction === SortDirection.ASC) {
          sortedCatalogItems.sort(sortCatalogItemsByPopularity);
        } else if (direction === SortDirection.DESC) {
          sortedCatalogItems.sort(sortCatalogItemsByPopularityDesc);
        }
      }

      return sortedCatalogItems;
    }

    return catalogItem;
  }
);

const getPagesNumber = createSelector(
  [getFilteredCatalogItems],
  (catalogItems) => Math.ceil(catalogItems.length / NUMBER_CATALOG_ITEMS_ON_PAGE)
);

const getCatalogItemIds = createSelector(
  [getAllCatalogItems],
  (catalogItems) => catalogItems.map((catalogItem) => catalogItem.id)
);

const getCartItems = createSelector(
  [getAllCatalogItems, getCartItemIdsObject],
  (allCatalogItems, cartItemIdsObject) => allCatalogItems.filter((item) => cartItemIdsObject.has(item.id))
);

const getFullCartPrice = createSelector(
  [getCartItems, getCartItemIdsObject],
  (cartItems, cartItemIdsObject) => cartItems
    .map((cartItem) => cartItemIdsObject.get(cartItem.id) * cartItem.price)
    .reduce((result, cost) => result + cost, 0)
);

export {
  getAllCatalogItems,
  getSortedCatalogItems,
  getPagesNumber,
  getCatalogItemIds,
  getCartItems,
  getFullCartPrice,
};
