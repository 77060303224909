import {StoreNameSpace} from '../constants';
import {Action as CatalogAction} from './actions/catalog';
import {Action as FilterAction} from './actions/filter';
import {Action as SortAction} from './actions/sort';
import {Action as PopupAction} from './actions/popup';
import {Action as CartAction} from './actions/cart';

const RootAction = {
  [StoreNameSpace.CATALOG]: CatalogAction,
  [StoreNameSpace.FILTER]: FilterAction,
  [StoreNameSpace.SORT]: SortAction,
  [StoreNameSpace.POPUP]: PopupAction,
  [StoreNameSpace.CART]: CartAction,
};

export default RootAction;
