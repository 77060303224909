import {combineReducers} from 'redux';
import {StoreNameSpace} from '../constants';
import catalogReducer from './reducers/catalog';
import filterReducer from './reducers/filter';
import sortReducer from './reducers/sort';
import popupReducer from './reducers/popup';
import cartReducer from './reducers/cart';

export default combineReducers({
  [StoreNameSpace.CATALOG]: catalogReducer,
  [StoreNameSpace.FILTER]: filterReducer,
  [StoreNameSpace.SORT]: sortReducer,
  [StoreNameSpace.POPUP]: popupReducer,
  [StoreNameSpace.CART]: cartReducer,
});
