import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as TriangleSvg} from './img/triangle.svg';
import './sort-direction-button.scss';

const DEFAULT_CLASS = 'sort-direction-button';
const ACTIVE_CLASS = `${DEFAULT_CLASS}--active`;

const SortDirectionButton = ({name, title, addClass, isActive, onDirectionButtonClick}) => {
  const classNames = [addClass, DEFAULT_CLASS, `${DEFAULT_CLASS}--${name}`];

  if (isActive) {
    classNames.push(ACTIVE_CLASS);
  }

  return (
    <button
      className={classNames.join(' ').trim()}
      type='button'
      data-direction={name}
      onClick={onDirectionButtonClick}
      aria-label={title}
      disabled={isActive}
      data-testid='sort-direction-button'
    >
      <TriangleSvg />
    </button>
  );
};

SortDirectionButton.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  addClass: PropTypes.string,
  isActive: PropTypes.bool,
  onDirectionButtonClick: PropTypes.func,
};

SortDirectionButton.defaultProps = {
  addClass: '',
};

export {SortDirectionButton};
export default SortDirectionButton;
