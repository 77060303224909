import React from 'react';
import Page from '../page/page';
import CartList from '../cart-list/cart-list';
import CartOrderPanel from '../cart-order-panel/cart-order-panel';
import './page-cart.scss';

const PageCart = () => (
  <Page
    headerClassName='main-header--cart'
    pageClassName='page-cart'
    footerClassName='main-footer--cart'
    title='Корзина'
    breadcrumbs={[
      {
        title: 'Главная',
        href: '/',
      },
      {
        title: 'Каталог',
        href: '/catalog',
      },
      {
        title: 'Оформляем',
        href: '/cart',
      },
    ]}
  >
    <CartList />
    <CartOrderPanel />
  </Page>
);

export {PageCart};
export default PageCart;
