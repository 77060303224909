import React from 'react';
import {Link} from 'react-router-dom';
import MainFooterBlockLinkWrapper from '../main-footer-block-link-wrapper/main-footer-block-link-wrapper';
import LogoLink from '../logo-link/logo-link';
import {ReactComponent as PinterestIconSvg} from './img/connect-pinterest.svg';
import {ReactComponent as VkIconSvg} from './img/connect-vk.svg';
import {ReactComponent as VscoIconSvg} from './img/connect-vsco.svg';
import PropTypes from 'prop-types';
import './main-footer.scss';

const ConnectLinks = [
  {
    title: 'Pinterest',
    href: '#pinterest',
    icon: PinterestIconSvg,
  },
  {
    title: 'VK',
    href: '#vk',
    icon: VkIconSvg,
  },
  {
    title: 'vsco',
    href: '#vsco',
    icon: VscoIconSvg,
  },
];

const CatalogLinks = [
  {
    title: 'Акустические гитары',
    href: '/catalog/acoustic',
  },
  {
    title: 'Классические гитары',
    href: '/catalog/classic',
  },
  {
    title: 'Электрогитары',
    href: '/catalog/electro',
  },
  {
    title: 'Бас-гитары',
    href: '/catalog/bass',
  },
  {
    title: 'Укулеле',
    href: '/catalog/ukulele',
  },
];
const NavLinks = [
  {
    title: 'Где купить?',
    href: '/buy',
  },
  {
    title: 'Блог',
    href: '/blog',
  },
  {
    title: 'Вопрос - ответ',
    href: '/faq',
  },
  {
    title: 'Возврат',
    href: '/refund',
  },
  {
    title: 'Сервис-центры',
    href: '/service',
  },
];

const MainFooter = (props) => (
  <footer
    className={['main-footer', props.addClass].join(' ').trim()}
    data-testid={'main-footer'}
  >
    <div className='main-footer__content-wrapper'>
      <LogoLink addClass='main-footer__logo' />
      <ul className='main-footer__connect-links'>
        {ConnectLinks.map(({title, href, icon: IconSvg}) => (
          <li className='main-footer__connect-link-wrapper' key={href}>
            <Link className='main-footer__connect-link' to={href} aria-label={title}>
              <IconSvg />
            </Link>
          </li>
        ))}
      </ul>
      <article className='main-footer__block main-footer__block--about'>
        <h2 className='main-footer__block-title'>О нас</h2>
        <p className='main-footer__block-paragraph'>Магазин гитар, музыкальных инструментов и гитарная мастерская в Санкт‑Петербурге.</p>
        <p className='main-footer__block-paragraph'>Все инструменты проверены, отстроены и доведены до идеала!</p>
      </article>
      <article className='main-footer__block main-footer__block--catalog'>
        <h2 className='main-footer__block-title'>Каталог</h2>
        <ul className='main-footer__block-links'>
          {CatalogLinks.map(({title, href}) => (<MainFooterBlockLinkWrapper key={href} title={title} href={href} />))}
        </ul>
      </article>
      <article className='main-footer__block main-footer__block--info'>
        <h2 className='main-footer__block-title'>Информация</h2>
        <ul className='main-footer__block-links'>
          {NavLinks.map(({title, href}) => (<MainFooterBlockLinkWrapper key={href} title={title} href={href} />))}
        </ul>
      </article>
      <article className='main-footer__block main-footer__block--contacts'>
        <h2 className='main-footer__block-title'>Контакты</h2>
        <p className='main-footer__block-paragraph main-footer__block-paragraph--narrow'>г. Санкт-Петербург, м. Невский проспект, ул. Казанская 6. <a href='tel:+78125005050'>8-812-500-50-50</a></p>
        <p className='main-footer__block-paragraph main-footer__block-paragraph--narrow'>Режим работы: <span className='main-footer__block-clock'>с 11:00 до 20:00,</span> без выходных.</p>
      </article>
    </div>
  </footer>
);

MainFooter.propTypes = {
  addClass: PropTypes.string,
};

MainFooter.defaultProps = {
  addClass: null,
};

export {MainFooter};
export default MainFooter;
