import PropTypes from 'prop-types';
import {GuitarTypeParamsObject} from './constants';

/**
* Тип данных для массива хлебных крошек используемых в компоненте MainHeader
*
* {title} - текстовое отображение ссылки
* {href} - адрес ссылки
*/
const breadcrumbsType = PropTypes.arrayOf(PropTypes.exact({
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
}));

/**
* Тип данных для объекта, представляющего товар из каталога
*
* {id} - идентификатор товара (порядковый номер записи в каталоге)
* {article} - артикул
* {name} - наименование товара
* {type} - тип товара, один из идентификаторов объекта GuitarTypeParamsObject
* {imgSrc} - путь до изображения товара
* {imgSrcset} - путь до изображения товара повышенной плотности пикселей
* {rate} - рейтинг (количество звезд)
* {stringsAmount} - количество струн у инструмента
* {price} - цена товара (числовой параметр)
* {priceTitle} - форматированная цена товара
* {description} - описание товара
* {reviewsNumber} - количество отзывов о товаре
*/
const catalogItemType = PropTypes.exact({
  id: PropTypes.string.isRequired,
  article: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    GuitarTypeParamsObject.acoustic.id,
    GuitarTypeParamsObject.electric.id,
    GuitarTypeParamsObject.ukulele.id,
  ]).isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgSrcSet: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
  stringsAmount: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  priceTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  reviewsNumber: PropTypes.number.isRequired,
});

/**
* Тип данных для массива товаров каталога
*/
const catalogItemsType = PropTypes.arrayOf(catalogItemType);

/**
* Тип данных для объекта описывающего
*
* {id} - идентификатор параметра количества струн у инструмента (совпадает с числовым значением параметра)
* {checked} - выбран ли параметр в фильтре
* {disabled} - может ли быть выбран параметр в фильтре (зависит от типа товара)
*/
const stringsAmountsStateType = PropTypes.objectOf(
  PropTypes.exact({
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
  }),
);

/**
* Тип данных для товаров в корзине
*
* Ключ объекта - идентификатор товара, значение - количество единиц товара в корзине
*/
const cartItemIdsObjectType = PropTypes.objectOf(PropTypes.number);

export {
  breadcrumbsType,
  catalogItemType,
  catalogItemsType,
  stringsAmountsStateType,
  cartItemIdsObjectType,
};
