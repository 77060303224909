import React from 'react';
import PropTypes from 'prop-types';
import './button-action.scss';

const BUTTON_ACTION_CLASS = 'button-action';
const BUTTON_ACTION_GHOST_CLASS = `${BUTTON_ACTION_CLASS}--ghost`;

const ButtonAction = ({title, className, type, onClick, isGhostMode}) => (
  <button
    className={[className, BUTTON_ACTION_CLASS, (isGhostMode ? BUTTON_ACTION_GHOST_CLASS : '')].join(' ').trim()}
    type={type}
    onClick={onClick}
    data-testid='button-action'
  >
    {title}
  </button>
);

ButtonAction.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  isGhostMode: PropTypes.bool,
};

ButtonAction.defaultProps = {
  className: '',
  type: 'button',
  isGhostMode: false,
};

export default ButtonAction;
