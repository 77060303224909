import React from 'react';
import {connect} from 'react-redux';
import CartListItem from '../cart-list-item/cart-list-item';
import {getCartItems} from '../../store/selectors';
import {catalogItemsType} from '../../types';
import './cart-list.scss';

const CartList = ({cartItems}) => (
  <section
    className='cart-list'
    data-testid='cart-list'
  >
    <h2 className='cart-list__title' data-testid='cart-list__title'>
      Список товаров в корзине
    </h2>
    {
      cartItems.length > 0
        ? (
          <ul className='cart-list__items' data-testid='cart-list__items'>
            {
              cartItems.map((cartItem) => (
                <CartListItem className='cart-list__item' key={cartItem.id} catalogItem={cartItem} />
              ))
            }
          </ul>)
        : (
          <p className='cart-list__info' data-testid='cart-list__info'>Корзина пуста</p>
        )
    }
  </section>
);

CartList.propTypes = {
  cartItems: catalogItemsType,
};

const mapStateToProps = (state) => ({
  cartItems: getCartItems(state),
});

export {CartList};
export default connect(mapStateToProps)(CartList);
